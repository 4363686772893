import React, { useState } from "react";
import axios from "axios";
import "./DashboardAll.css";
import { Select, Button, Table, Spin } from "antd";
import ReactApexChart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;

function Dashboard() {
	const [selectedPeriod, setSelectedPeriod] = useState("");
	const [selectedForecastingPeriod, setSelectedForecastingPeriod] =
		useState("");
	const [responseData, setResponseData] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleSubmit = () => {
		// Check if both select fields are selected
		if (!selectedPeriod || !selectedForecastingPeriod) {
			alert("Please select both period and forecasting period.");
			return; // Exit the function if any field is not selected
		}

		setLoading(true);

		const requestData = new FormData();
		requestData.append("periods", selectedPeriod);
		requestData.append("frequency", selectedForecastingPeriod);

		axios
			.post("https://timeseries.brainvire.net/Sales-Forcasting/", requestData)
			.then((response) => {
				setResponseData(response.data);
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const columns = [
		{
			title: "Months/Years",
			dataIndex: "ds",
			key: "ds",
		},
		{
			title: "Predicted Sale",
			dataIndex: "predicted_value",
			key: "predicted_value",
		},
		{
			title: "Percent wise sale growth",
			dataIndex: "percentage_difference",
			key: "percentage_difference",
		},
	];

	return (
		<>
			<div className="working__area close__sidebar dashboard">
				<div className="px-3 py-2 sale-dashboard mb-2">
					<h3>Sales Dashboard</h3>
				</div>
				<div className="row">
					<div className="select-sec d-flex justify-content-between mb-4">
						<div className="col-md-4 px-4">
							<div>
								<label style={{ marginRight: "10px" }}>Periods</label>
							</div>
							<Select
								value={selectedPeriod}
								placeholder="Periods"
								onChange={(value) => setSelectedPeriod(value)}
								style={{ width: 120, marginRight: 10 }}>
								<Option value="" disabled hidden>
									Periods
								</Option>
								{[...Array(12).keys()].map((month) => (
									<Option key={month + 1} value={month + 1}>
										{month + 1}
									</Option>
								))}
							</Select>
						</div>
						<div className="col-md-4 px-4">
							<div>
								<label style={{ marginRight: "10px" }}>
									Forecasting Period
								</label>
							</div>
							<Select
								value={selectedForecastingPeriod}
								onChange={(value) => setSelectedForecastingPeriod(value)}
								placeholder="Months"
								style={{ width: 120 }}>
								<Option value="" disabled hidden>
									Select
								</Option>
								<Option value="M">Month</Option>
								<Option value="Y">Year</Option>
							</Select>
						</div>
						<div className="col-md-4">
							<Button className="mt-4" type="primary" onClick={handleSubmit}>
								{loading ? <Spin /> : "Submit"}
								{loading ? null : (
									<FontAwesomeIcon
										className="mx-2 font-weight-bold"
										icon={faCheck}
									/>
								)}
							</Button>
						</div>
					</div>
				</div>
				{responseData && (
					<>
						<div className="chart-sec alert alert-primary mt-0 ">
							{/* <h4>Headlines:</h4> */}
							<p>{responseData.Headlines}</p>
						</div>
						<div className="row mt-4">
							<div className="col-md-5 chart-sec">
								<h4>Forecasted Data:</h4>
								<hr />
								<Table
									dataSource={responseData.forcast_data}
									columns={columns}
									pagination={{ pageSize: 5 }}
								/>
							</div>

							<div className="col-md-6 chart-sec forcast-chart pb-2">
								<h4>Forecasted Graph:</h4>
								<hr />
								<ReactApexChart
									options={{
										xaxis: {
											categories: responseData.forcast_data.map(
												(item) => item.ds
											),
										},
										colors: ["#EA6A47"],
									}}
									series={[
										{
											name: "Predicted Value",
											data: responseData.forcast_data.map(
												(item) => item.predicted_value
											),
										},
									]}
									type="bar"
									height={350}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default Dashboard;
