// import React from "react";

// const InputField = ({
// 	id,
// 	type,
// 	placeholder,
// 	label,
// 	onChange,
// 	required,
// 	value,
// }) => {
// 	return (
// 		<div className="form-group">
// 			<div className="d-flex justify-content-between my-2">
// 				<label htmlFor={id}>{label}</label>
// 			</div>
// 			<input
// 				type={type}
// 				className="form-control mb-0"
// 				id={id}
// 				value={value}
// 				placeholder={placeholder}
// 				onChange={onChange}
// 				{...(required && { required: true })}
// 			/>
// 		</div>
// 	);
// };

// export default InputField;
import React from "react";
import { Form, Input } from "antd";

const InputField = ({
	id,
	type,
	placeholder,
	label,
	onChange,
	required,
	value,
}) => {
	return (
		<Form.Item
			label={label}
			name={id}
			rules={[{ required: required, message: `Please input your ${label}!` }]}>
			<Input
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
			/>
		</Form.Item>
	);
};

export default InputField;
