import Sidebar from "../Sidebar/Sidebar";
import Navigation from "../Navigation/Navigation";
import "../../App.css";
import axios from "axios";
import React, { useState } from "react";
import { Upload, Button, message, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { apiEndPoints } from "../../constants/apiEndPoints";

export default function InputFile() {
	const [file, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);

	const handleUpload = async () => {
		const formData = new FormData();

		formData.append("file", file);

		try {
			setUploading(true);
			const token = localStorage.getItem("token");
			const response = await axios.post(apiEndPoints.input_file, formData, {
				headers: {
					Authorization: `Token ${token}`,
					"Content-Type": "multipart/form-data",
				},
			});

			message.success("Files uploaded successfully");
			setFileList([]);
			document.querySelector(".ant-upload-list").innerHTML = "";
		} catch (error) {
			console.error("Error occurred:", error);

			let errorMessage =
				"An error occurred while processing your request. Please try again later.";

			if (
				error.response &&
				error.response.data &&
				error.response.data.message
			) {
				errorMessage = error.response.data.message;
			}

			message.error(errorMessage);
		} finally {
			setUploading(false);
		}
	};
	const handleChange = (info) => {
		setFileList(info.file);
	};

	return (
		<>
			<div className="working__area close__sidebar ">
				<div className="background-sec ">
					<Upload.Dragger
						accept=".pdf"
						onChange={handleChange}
						beforeUpload={() => false}>
						<p className="ant-upload-drag-icon">
							<UploadOutlined />
						</p>
						<p className="ant-upload-text">
							Click or drag PDF files to this area to upload
						</p>
					</Upload.Dragger>

					<Button
						type="primary"
						onClick={handleUpload}
						disabled={file.length === 0}
						style={{ marginTop: 16 }}>
						{uploading ? <Spin /> : "Upload"}
					</Button>
				</div>
			</div>
		</>
	);
}
