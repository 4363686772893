import React, { useState } from "react";
import { Form, Input, Button, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import "./Login.css"; // Import the CSS file

const hardcodedCredentials = {
	username: "admin",
	password: "1234",
};

export default function Login({ setLoggedIn }) {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const handleLoginSuccess = (token) => {
		setLoggedIn(true);
		sessionStorage.setItem("loggedIn", "true");
		navigate("/dashboard");
	};
	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			if (
				values.username === hardcodedCredentials.username &&
				values.password === hardcodedCredentials.password
			) {
				message.success("Logged in successfully!");
				handleLoginSuccess();
				navigate("/dashboard");
			} else {
				message.error("Invalid username or password");
			}
		} catch (error) {
			console.error("Error occurred:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<section className="login-in-page">
			<div className="login-in-page-bg">
				<div className="">
					<div className="login-in-from">
						<h1 className="prestige-heading">Prestige</h1>
						<h3 className="mb-0 mt-2">Sign in</h3>

						<Form className="mt-4" onFinish={handleSubmit}>
							<Form.Item
								name="username"
								label="Username"
								rules={[
									{ required: true, message: "Please input your username!" },
								]}>
								<Input placeholder="Enter username" className="p-2 mx-2" />
							</Form.Item>
							<Form.Item
								name="password"
								label="Password"
								rules={[
									{ required: true, message: "Please input your password!" },
								]}>
								<Input.Password
									placeholder="Enter Password"
									className="p-2 mx-2"
								/>
							</Form.Item>
							<Form.Item className="button-login-p">
								<Button type="" htmlType="submit" className="float-center ">
									{loading ? <Spin /> : "Log In"}
								</Button>
							</Form.Item>
						</Form>
					</div>
				</div>
			</div>
		</section>
	);
}
// import React, { useState } from "react";
// import "./Login.css"; // Make sure to import your CSS file
// import { Form, Input, Button, message, Spin } from "antd";
// import { useNavigate } from "react-router-dom";
// function Login({ setLoggedIn }) {
// 	const [username, setUsername] = useState("");
// 	const [password, setPassword] = useState("");
// 	const [isSignUpMode, setIsSignUpMode] = useState(false);
// 	const [loading, setLoading] = useState(false);

// 	const navigate = useNavigate();

// 	const hardcodedCredentials = {
// 		username: "admin",
// 		password: "1234",
// 	};
// 	const handleSignUpMode = () => {
// 		setIsSignUpMode(true);
// 	};

// 	const handleSignInMode = () => {
// 		setIsSignUpMode(false);
// 	};
// 	const handleLoginSuccess = (token) => {
// 		setLoggedIn(true);
// 		sessionStorage.setItem("loggedIn", "true");
// 		navigate("/dashboard");
// 	};
// 	const handleSubmit = async (values) => {
// 		setLoading(true);
// 		try {
// 			if (
// 				values.username === hardcodedCredentials.username &&
// 				values.password === hardcodedCredentials.password
// 			) {
// 				message.success("Logged in successfully!");
// 				handleLoginSuccess();
// 				navigate("/dashboard");
// 			} else {
// 				message.error("Invalid username or password");
// 			}
// 		} catch (error) {
// 			console.error("Error occurred:", error);
// 		} finally {
// 			setLoading(false);
// 		}
// 	};

// 	return (
// 		<div className={`container ${isSignUpMode ? "sign-up-mode" : ""}`}>
// 			<div className="forms-container">
// 				<div className="signin-signup">
// 					<form onSubmit={handleSubmit} className="sign-in-form">
// 						<h2 className="title">Sign in</h2>
// 						<div className="input-field">
// 							<i className="fas fa-user"></i>
// 							<input
// 								type="text"
// 								value={username}
// 								onChange={(e) => setUsername(e.target.value)}
// 								placeholder="Username"
// 								required
// 							/>
// 						</div>
// 						<div className="input-field">
// 							<i className="fas fa-lock"></i>
// 							<input
// 								type="password"
// 								value={password}
// 								onChange={(e) => setPassword(e.target.value)}
// 								placeholder="Password"
// 								required
// 							/>
// 						</div>
// 						<button type="submit"> {loading ? <Spin /> : "Log In"}</button>
// 						{/* <input type="submit" value="Login" className="btn solid" /> */}
// 					</form>

// 					<form action="#" className="sign-up-form">
// 						<h2 className="title">Sign up</h2>
// 						<div className="input-field">
// 							<i className="fas fa-user"></i>
// 							<input type="text" placeholder="Username" />
// 						</div>
// 						<div className="input-field">
// 							<i className="fas fa-envelope"></i>
// 							<input type="email" placeholder="Email" />
// 						</div>
// 						<div className="input-field">
// 							<i className="fas fa-lock"></i>
// 							<input type="password" placeholder="Password" />
// 						</div>
// 						<div className="input-field">
// 							<i className="fas fa-lock"></i>
// 							<input type="password" placeholder="Confirm Password" />
// 						</div>
// 						<input type="submit" className="btn" value="Sign up" />
// 					</form>
// 				</div>
// 			</div>

// 			<div className="panels-container">
// 				<div className="panel left-panel">
// 					<div className="content">
// 						<h3>New here?</h3>
// 						<p>Become one of us by signing up from the button below!</p>
// 						<button
// 							className="btn transparent"
// 							onClick={handleSignUpMode}
// 							id="sign-up-btn">
// 							Sign up
// 						</button>
// 					</div>
// 					<img src="Images/log.svg" className="image" alt="" />
// 				</div>
// 				<div className="panel right-panel">
// 					<div className="content">
// 						<h3>One of us?</h3>
// 						<p>
// 							Let's see what's happening inside! Sign in from the button below.
// 						</p>
// 						<button
// 							className="btn transparent"
// 							onClick={handleSignInMode}
// 							id="sign-in-btn">
// 							Sign in
// 						</button>
// 					</div>
// 					<img src="Images/register.svg" className="image" alt="" />
// 				</div>
// 			</div>
// 			<div className="logo">
// 				<img
// 					className="company_logo_signup"
// 					src="./img/brainvire-logo.webp"
// 					alt="logo"
// 				/>
// 			</div>
// 			<div className="logo">
// 				<img
// 					className="company_logo_login"
// 					src="./img/brainvire-logo.webp"
// 					alt="logo"
// 				/>
// 			</div>
// 		</div>
// 	);
// }

// export default Login;
